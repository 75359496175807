import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";

export const FavoritesComponent = () => {
  const { apiOrigin = "https://ztf8mx7a9k.execute-api.us-east-1.amazonaws.com", audience } = getConfig();
  const { user, getAccessTokenSilently } = useAuth0();
  const queryParams = new URLSearchParams(window.location.search);
  const state_redirect = queryParams.get('state');

  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
    firstName: "",
    lastName: "",
    flavor: "",
    creditCard: "",
    expireMonth: "1",
    expireYear: "2022"
  });

  const url = {
    limon: "https://estaticos-cdn.prensaiberica.es/clip/09fe558b-6dc6-4a51-93d5-5c758cf403a4_16-9-aspect-ratio_default_0.jpg",
    fresa: "https://www.redaccionmedica.com/images/destacados/las-fresas-mejoran-los-sintomas-de-la-enfermedad-inflamatoria-intestinal-8046.jpg",
    chocolate: "https://i1.wp.com/www.adelantosdigital.com/web/wp-content/uploads/2017/01/chocolate-negro.jpg",
    vainilla: "https://topadventure.com/__export/1606080702127/sites/laverdad/img/2020/11/22/flor_de_la_vainilla.jpg_423682103.jpg",
    all: "https://p4.wallpaperbetter.com/wallpaper/195/152/8/balls-colored-ice-cream-horn-wallpaper-preview.jpg"
  }
  let ignore = false;
  const jumpStep = async () => {
    ignore = true;
    callApi();
  }

  const callApi = async () => {
    const oauth_token = await getAccessTokenSilently();
    
    const token = await fetch(
      apiOrigin + "/token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${oauth_token}`
        },
        body: JSON.stringify({
          id: user.sub,
          fsName: state.firstName !== '' ? state.firstName : "Nombre",
          laName: state.lastName !== '' ? state.lastName : "Apellido",
          flavor: state.flavor !== '' ? state.flavor : "all",
          st: state_redirect,
          ignore: ignore
        }),
      }
    );
    const responseData = await token.json();

    //Crear usuario en la base de datos de Mongo

    const card = state.creditCard;
    let cardaux = "";
    for (let index = 0; index < card.length; index++) {
      const element = card.charAt(index);
      cardaux = (index % 4 === 0 && index >= 4) ? cardaux + ' ' + element : cardaux + element;
    }
    const nombreCompleto = (givenName, surname) => givenName + ' ' + surname;
    const mes = (Number(state.expireMonth) < 10) ? '0' + state.expireMonth : state.expireMonth;
    const year = state.expireYear.substring(2);

    const res_usuario = await fetch(
      apiOrigin + "/users",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${oauth_token}`
        },
        body: JSON.stringify({
          _id: user.sub,
          fullname: nombreCompleto(
            state.firstName !== '' ? state.firstName : "Nombre",
            state.lastName !== '' ? state.lastName : "Apellido"),
          email: user.email,
        }),
      }
    );
    const responseUser = await res_usuario.json();

    window.location.href = "https://tec360-iam.us.auth0.com/continue?state=" + state_redirect + "&token=" + responseData.id_token;
  };

  const handleChange = (event) => {
    console.log(event.target.id);
    switch (event.target.id) {
      case "firstName":
        setState({ ...state, firstName: event.target.value });
        break;
      case "lastName":
        setState({ ...state, lastName: event.target.value });
        break;
      case "flavor":
        setState({ ...state, flavor: event.target.value });
        break;
      case "month":
        setState({ ...state, expireMonth: event.target.value });
        break;
      case "year":
        setState({ ...state, expireYear: event.target.value });
        break;
      case "creditCard":
        setState({ ...state, creditCard: event.target.value });
        break;
    }
  };
  const divStyle = {
    width: '500px',
    align: 'center'
  };
  const divExpiration = {
    width: '50px',
    align: 'center'
  };
  const buttonCSS = {
    marginLeft: '0.8rem'
  }

  return (
    <>
      <div className="mb-5" style={divStyle}>
        <h1>Completa tu informacion personal</h1>
        <Form className="form">
          <FormGroup>
            <Label for="firstName">Nombre</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              onChange={handleChange}
            />
            <Label for="lastName">Apellido</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              onChange={handleChange}
            />
            <Label for="flavors">¿Cuál es tu sabor preferido de helado?</Label>
            <div onChange={handleChange} id="flavors">
              <div> <input type="radio" name="sabor" value="limon" id="flavor" />Limon<img src={url.limon} width="50px" /></div>
              <div><input type="radio" name="sabor" value="fresa" id="flavor" />Fresa<img src={url.fresa} width="50px" /></div>
              <div><input type="radio" name="sabor" value="chocolate" id="flavor" />Chocolate<img src={url.chocolate} width="50px" /></div>
              <div><input type="radio" name="sabor" value="vainilla" id="flavor" />Vainilla<img src={url.vainilla} width="50px" /></div>
              <div><input type="radio" name="sabor" value="all" id="flavor" />Todas las anteriores<img src={url.all} width="50px" /></div>
            </div>
          </FormGroup>
          <Button
            color="primary"
            className="mt-5"
            onClick={callApi}
            disabled={!audience}
          >
            Guardar
          </Button>
          <Button
            color="warning"
            className="mt-5"
            style={buttonCSS}
            onClick={jumpStep}
            disabled={!audience}
          >
            Lo haré más tarde
          </Button>
        </Form>
      </div>
    </>
  );
};

export default withAuthenticationRequired(FavoritesComponent, {
  onRedirecting: () => <Loading />,
});
